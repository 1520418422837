@import "../../shared/styles/config.scss";

.pagination {
  display: flex;
  align-items: center;

  .pageInfo {
    color: $standard-text-color;
    margin: 0 1rem;
    min-width: 6rem;
    text-align: center;
  }
}
