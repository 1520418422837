@import "../shared/styles/config.scss";

.container {
  box-sizing: content-box;
  margin: 0 auto;
  max-width: 1100px;
  padding: 1rem;
}

.header {
  border-bottom: 1px solid $standard-color;
  color: $accent-color;
  padding-bottom: 1rem;
}

.main {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
